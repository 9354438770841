// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import App from "./App";
import { storeAWSConfig } from "./utils/loadAndGetLocalStorage";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  DefaultOptions,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { PersistGate } from "redux-persist/integration/react";
import { setContext } from "@apollo/client/link/context";
import { configureAuth, getCurrentAuthenticatedUser, getJwtToken } from "./utils/AuthWrapper";
import { initGraphQL } from "./utils/providerServices";
import { ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./theme";
import { ToastContainer } from "react-toastify";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-perfect-scrollbar/dist/css/styles.css";
import { ErrorToaster } from "./toast";
import { handleError } from "./ErrorHandler";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { enableLogRocket, setLogRocketIdentity } from "./utils/logRocket";

storeAWSConfig({
  awsRegion: "us-west-2",
  userPoolId: "us-west-2_i3WAKpxkg",
  userPoolWebClientId: "3voriu33cjp39oi9194fmhblhp",
  authenticationFlowType: "USER_SRP_AUTH",
  mandatorySignIn: false,
});

let apiAddress = process.env.REACT_APP_API_ADDRESS
  ? process.env.REACT_APP_API_ADDRESS
  : "localhost";

let httpsString = process.env.REACT_APP_HTTPS ? process.env.REACT_APP_HTTPS : "https";
let uriGQL = `${httpsString}://${apiAddress}/graphql`;
console.log(`Looking for gql at ${uriGQL}`);
const httpLink = createHttpLink({
  uri: uriGQL,
});

configureAuth();
enableLogRocket();
const authLink = setContext(async (_, { headers }) => {
  const user = await getCurrentAuthenticatedUser();
  // get the authentication token from local storage if it exists
  let token: string | null = null;

  if (user) {
    setLogRocketIdentity(user.email, user.firstName + " " + user.lastName, user.companyName);
    token = await getJwtToken();
  }
  // return the headers to the context so httpLink can read them
  let headersToSend = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
  return headersToSend;
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const graphqlClient = new ApolloClient<any>({
  cache: new InMemoryCache(),
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      let errorCode = "";
      if (networkError) {
        errorCode = networkError.statusCode;
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions: { code } }) => {
          const msg = handleError(errorCode ? errorCode : code, message);
          if (msg) {
            ErrorToaster(msg);
          }
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, ${code}`
          );
        });
      }
    }),
    authLink.concat(httpLink),
  ]),
  defaultOptions: defaultOptions,
});
initGraphQL(graphqlClient);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <ToastContainer />
        <ApolloProvider client={graphqlClient}>
          <ThemeProvider theme={defaultTheme}>
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
