import { Button, Checkbox, FormHelperText, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import TextField from "../../../Common/TextField/index";
import { StepsProps, useStyles } from "../../styles";
import Title from "../Title/Index";

function Index(props: StepsProps) {
  const { formik } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <div className="p-7">
      <Title style={{ marginBottom: 39 }}>
        <>
          Create your account with capabl
          {/* - <span className={classes.link}>Its free!</span> */}
        </>
      </Title>
      {arrayFields.map((field, index) => (
        <TextField
          {...field}
          {...formik.getFieldProps(field.name)}
          formik={formik}
          key={index}
          className={classes.textField}
        />
      ))}
      <div className="flex flex-row">
        <Checkbox
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue("terms", event.target.checked)
          }
          checked={formik.values.terms}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
        />

        <Typography variant="body2">
          By clicking create my account, you agree with to our{" "}
          <Link to="/" className={classes.link}>
            {" "}
            Term and Conditions
          </Link>
        </Typography>
      </div>
      {formik?.errors["terms"]?.length && formik?.touched["terms"] && (
        <FormHelperText style={{ color: "#d32f2f" }}>{formik?.errors["terms"]}</FormHelperText>
      )}
      <Button
        color="primary"
        disabled={formik.isSubmitting || formik.isValidating || !formik.isValid}
        onClick={formik.handleSubmit}
        className={classes.accountBtn}
      >
        Create my account
      </Button>
      <Typography variant="body2" align="center">
        Already have an account?{" "}
        <Link
          onClick={() => {
            dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
          }}
          to="/"
          className={classes.link}
        >
          Sign in
        </Link>
      </Typography>
    </div>
  );
}

export default Index;

const arrayFields = [
  { name: "email", label: "Email", type: "text" },
  { name: "password", label: "Password", type: "password", isPassword: true },
  { name: "confirmPassword", label: "Confirm Password", type: "password", isPassword: true },
];
