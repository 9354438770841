import { ThemeProvider } from "@mui/material";
import Reports from "./Reports";
import { reportsTheme } from "./theme";

export default function Index() {
  return (
    <ThemeProvider theme={reportsTheme}>
      <Reports />
    </ThemeProvider>
  );
}
