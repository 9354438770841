import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

function Index(props: TextFieldProps) {
  const { className, label, type, formik, isPassword, style, ...rest } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [fieldType, setFieldType] = useState("");

  useEffect(() => {
    if (isPassword) {
      setFieldType(isVisible ? "text" : "password");
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TextField
      className={`${className}`}
      label={label}
      style={style || {}}
      {...rest}
      type={fieldType || type}
      variant="filled"
      size="medium"
      InputProps={
        isPassword
          ? {
              endAdornment: isVisible ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsVisible(!isVisible)}>
                    <VisibilityOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsVisible(!isVisible)}>
                    <VisibilityOffOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}
      }
      error={(formik?.errors[rest.name]?.length && formik?.touched[rest.name]) || false}
      helperText={
        formik?.errors[rest.name]?.length && formik?.touched[rest.name]
          ? formik?.errors[rest.name]
          : ""
      }
    />
  );
}

export default Index;

interface TextFieldProps {
  className?: string;
  label: string;
  name: string;
  style: React.CSSProperties;
  formik?: any;
  type?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
  value?: string;
  isPassword?: boolean;
}
