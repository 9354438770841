import {
  Alert,
  AlertColor,
  Box,
  createTheme,
  Snackbar,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import capablLogo from "../../Resources/onboarding/capabl-icon.png";
import { makeStyles } from "@mui/styles";
import { Loader } from "../../loading";
import ButtonControl from "../Common/Input/Button";
import Input from "../Common/Input/Input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { userSignIn } from "../../utils/signInHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootInitStateInterface } from "../../store/reducers/root";

export interface loginInfo {
  redirectUri?: string;
  showForgotPass: () => void;
  openAlertMsg: boolean;
  alertSeverity: string;
  showAlert: (message: string, severity: AlertColor, open: boolean) => void;
  hideAlert: () => void;
  alertMessage: string;
  isShopInprocess: () => void;
}

function UserLogin(loginProps: loginInfo) {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const { shopName, token }: RootInitStateInterface = useSelector((state: any) => state.root);
  const [loadings, setLoadings] = useState<boolean>(false);
  const [password, setPassword] = useState<string>(""),
    [emailAddress, setEmailAddress] = useState<string>("");
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box className={`flex flex-col items-center ${classes.boxRoot}`}>
      {loadings && <Loader />}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={loginProps.hideAlert}
        open={loginProps.openAlertMsg}
        autoHideDuration={3000}
      >
        <Alert severity={loginProps.alertSeverity as AlertColor} sx={styles.snackbar}>
          {loginProps.alertMessage}
        </Alert>
      </Snackbar>
      <img src={capablLogo} style={styles.capablLogo} alt="" />
      <Typography variant="h5" mt={4}>
        Login To Your Account
      </Typography>
      <Box sx={styles.innerBox}>
        <Input
          label="Email"
          type="text"
          fullWidth={true}
          value={emailAddress}
          className={`${classes.inputField} ${classes.outlinedInput}`}
          onChange={setEmail}
          onKeyDown={keyDown}
          isShowPassword={true}
        />
      </Box>
      <Box sx={styles.innerBox}>
        <Input
          label="Password"
          type="password"
          fullWidth={true}
          className={`${classes.inputField} ${classes.outlinedInput}`}
          id="outlined-adornment-password"
          value={password}
          isShowPassword={isShowPassword}
          onChange={setUserPassword}
          onKeyDown={keyDown}
          icon={
            <IconButton
              aria-label="toggle password visibility"
              onClick={setShowPassword}
              edge="end"
            >
              {isShowPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
        />
      </Box>

      <p className={classes.forgotPasswordContainer}>
        <span onClick={() => loginProps.showForgotPass()} style={styles.forgotPasswordText}>
          {" "}
          FORGOT PASSWORD?
        </span>
      </p>

      <ButtonControl
        variant="contained"
        colorName="primary"
        className={classes.signInButton}
        size="large"
        label="Sign In"
        onClick={userSignin}
      />

      <p style={styles.accountText}>
        Don't have an account?
        <span onClick={redirectToSignup} style={styles.themeGreen}>
          CREATE AN ACCOUNT
        </span>
      </p>
    </Box>
  );

  /**
   * redirect user to signup page
   */
  function redirectToSignup() {
    history.push("/signup" + history.location.search);
  }

  /**
   * set email address
   */
  function setEmail(event: { target: { value: any } }) {
    setEmailAddress(event.target.value);
  }

  /**
   * set password
   */
  function setUserPassword(event: { target: { value: any } }) {
    setPassword(event.target.value);
  }

  /**
   * toggle password visibility
   */
  function setShowPassword() {
    setIsShowPassword(!isShowPassword);
  }

  /**
   * if user hits enter, sign in the user.
   */
  function keyDown(event: { key: string }) {
    if (event.key === "Enter") {
      userSignin();
    }
  }

  /**
   * sign in the user
   */
  async function userSignin() {
    await userSignIn({
      emailAddress: emailAddress,
      password: password,
      setLoadings: setLoadings,
      token,
      shopName,
      loginProps: {
        showAlert: loginProps.showAlert,
        isShopInprocess: loginProps.isShopInprocess,
      },
      history: { push: history.push, location: { search: history.location.search } },
      dispatch,
    });
  }
}

const theme = createTheme();

/**
 * @styles defines styles for this component
 */
const styles = {
  snackbar: {
    width: window.innerWidth * 0.4,
  },
  capablLogo: {
    width: "165px",
    height: "35px",
  },
  titleHeading: {
    marginTop: "25px",
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  accountText: {
    color: "#808080",
    fontSize: "14px",
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    marginTop: "50px",
  },
  forgotPasswordText: {
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    color: "#07A95B",
    cursor: "pointer",
  },
  themeGreen: {
    paddingLeft: "5px",
    color: "#07A95B",
    cursor: "pointer",
    textDecoration: "underline",
  },
};

export default UserLogin;

const useStyles = makeStyles({
  inputField: {
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
  },
  outlinedInput: {
    width: "390px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  signInButton: {
    backgroundColor: "#07A95B",
    color: "white",
    marginTop: "30px",
    maxWidth: "390px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#07A95B",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      "&:hover": {
        backgroundColor: "#07A95B",
      },
    },
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    textTransform: "capitalize",
    height: "47px",
  },
  boxRoot: {
    backgroundColor: "white",
    padding: "40px 0px",
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "40px 30px",
      height: "max-content",
    },
    height: "530px",
    position: "relative",
  },
  forgotPasswordContainer: {
    maxWidth: "390px",
    width: "100%",
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
});
