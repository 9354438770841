import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TextField from "../../../Common/TextField/index";
import { LoginFormProps, useStyles } from "../../styles";
import Title from "../../Main/Title/Index";

function Index(props: LoginFormProps) {
  const { formik } = props;
  const classes = useStyles();

  return (
    <div className="p-7">
      <Title style={{ marginBottom: 39 }}>
        <>
          Access your account with capabl
          {/* - <span className={classes.link}>Its free!</span> */}
        </>
      </Title>
      {arrayFields.map((field, index) => (
        <TextField
          {...field}
          {...formik.getFieldProps(field.name)}
          formik={formik}
          key={index}
          className={classes.textField}
        />
      ))}
      <Button color="primary" onClick={formik.handleSubmit} className={classes.accountBtn}>
        Login
      </Button>
      <Typography variant="body2" align="center">
        Don't have an account?{" "}
        <Link to="/signup" className={classes.link}>
          Create
        </Link>
      </Typography>
    </div>
  );
}

export default Index;

const arrayFields = [
  { name: "email", label: "Email", type: "text" },
  { name: "password", label: "Password", type: "text", isPassword: true },
];
