import { Grid, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonAppBar from "../../Common/Appbar/Index";
import { useHistory } from "react-router-dom";
import LeftSide from "../LeftSide/Index";
import RightSide from "../RightSide/Index";
import { useFormik } from "formik";
import axios from "axios";
import { ErrorToaster, SuccessToaster, WarningToaster } from "../../../toast";
import { signupInitialValues, SignupSteps, signUpValidationSchema } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { OnboardInintState } from "../../../store/reducers/onboard";
import { signUp } from "../../../utils/AuthWrapper";
import "../Index.css";
import { theme } from "../../../NewTheme";
import { OnboardingInintState } from "../../../store/reducers/onboarding";
import { Loader } from "../../../NewLoadingScreen";

function Index() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, setLoading] = useState(true);
  const state: OnboardInintState = useSelector((state: any) => state.onboard);
  const { stepCount, loader }: OnboardingInintState = useSelector((state: any) => state.onboarding);

  const [step, setStep] = React.useState<number>(0); //state.stepCount);
  const [init, setInit] = useState(true);
  const CurrentStep = SignupSteps[step];
  console.log(stepCount, step, CurrentStep, "stepCount in signup");
  useEffect(() => {
    if (stepCount >= 2) {
      history.push("/");
    } else if (stepCount > step && stepCount <= 2) {
      setStep(stepCount);
    }
  }, [stepCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const signUpformik = useFormik({
    initialValues: signupInitialValues,
    validateOnChange: true,
    validationSchema: signUpValidationSchema,
    onSubmit: async function () {
      dispatch({ type: "ONBOARDING_LOADER", payload: true });
      await signupUser();
      signUpformik.resetForm();
      signUpformik.setSubmitting(false);
      dispatch({ type: "ONBOARDING_LOADER", payload: false });
    },
  });

  console.log(state, " 2 state");

  useEffect(() => {
    const { email, password, confirmPassword, firstName, lastName, businessName, terms } = state;
    signUpformik.setValues({
      email,
      password,
      confirmPassword,
      terms,
      firstName,
      lastName,
      businessName,
    });
    setStep(stepCount);
    setTimeout(() => {
      setInit(false);
    }, 500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!init) {
      console.log("first");
      dispatch({ type: "CHANGE_FORM", payload: { ...signUpformik.values } });
    }
  }, [signUpformik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={theme}>
      <div className="bg-white" style={{ minHeight: "100vh" }}>
        <ButtonAppBar />
        <div
          style={{
            width: loader ? "100%" : "90%",
            margin: "auto",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          {loader && <Loader content={signupLoaderText[step] || ""} />}
          <Grid container className="py-20" columnSpacing={3}>
            <Grid item xs={12} sm={5} md={6} lg={step < 12 ? 8 : 7.5}>
              <LeftSide step={step} />
            </Grid>
            <Grid item xs={12} sm={7} md={6} lg={step < 12 ? 4 : 4.5}>
              <RightSide>
                <CurrentStep step={step} formik={signUpformik} />
              </RightSide>
            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );

  /**
   * Signs up the user
   * @returns a string if error, otherwise null
   */
  async function signupUser() {
    const { password, email, firstName, lastName, businessName }: any = signUpformik.values;

    try {
      setLoading(true);
      let user = await signUp(businessName, email, password, firstName, lastName);
      if (process.env.NODE_ENV === "production") {
        await axios.post(
          "https://api.hsforms.com/submissions/v3/integration/submit/" +
            process.env.REACT_APP_PORTAL_ID +
            "/" +
            process.env.REACT_APP_FORM_ID,
          generateData(email, firstName, lastName, "", "", "", businessName)
        );
      }
      if (user) {
        setTimeout(() => history.push("/"), 1000);
        setStep(0);
        SuccessToaster("Please check your email to verify");
        dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
      }
    } catch (error: any) {
      if (
        error &&
        error?.response &&
        error?.response?.data?.errors &&
        error.response.data.errors[0]
      ) {
        WarningToaster(error.response.data.errors[0].message);
        SuccessToaster("Please check your email to verify");
        setTimeout(() => history.push("/"), 10);
        dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
      } else {
        ErrorToaster(`${(error as Error).message}`);
        dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
        dispatch({ type: "ONBOARDING_LOADER", payload: false });
        setStep(0);
        console.log(`${(error as Error).message}`);
      }
      return (error as Error).message;
    } finally {
      setLoading(false);
    }
    return null;
  }

  function generateData(
    email: string,
    firstname: string,
    lastname: string,
    utm_campaign: string,
    utm_medium: string,
    lead_source: string,
    company: string
  ) {
    return {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstname,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: lastname,
        },
        {
          objectTypeId: "0-1",
          name: "utm_campaign",
          value: utm_campaign,
        },
        {
          objectTypeId: "0-1",
          name: "utm_medium",
          value: utm_medium,
        },
        {
          objectTypeId: "0-1",
          name: "lead_source",
          value: lead_source,
        },
        {
          objectTypeId: "0-1",
          name: "company_name",
          value: company,
        },
      ],
    };
  }
}

export default Index;

const signupLoaderText = {
  1: "Please hang tight as we are creating your account",
};
