import { Avatar, List, ListItem, ListItemText, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { StepsProps } from "../../styles";
import Stepper from "../Stepper/Index";
import Title from "../Title/Index";
import Image from "../../../../Resources/onboarding/image1.png";
import ShopifyDialog from "./Popup";
import {
  finalizeOnboarding,
  initiateOnboarding,
  markShopActive,
  ShopExists,
  verifyShopAuth,
} from "../../../../utils/providerServices";
import {
  DayOfWeek,
  DurationType,
  OnboardingInput,
  PricingPlan,
  ShippingWindowType,
  ShopFc,
  ShopType,
} from "../../../../generated/graphql";
import { ErrorToaster } from "../../../../toast";
import { useDispatch, useSelector } from "react-redux";
import { RootInitStateInterface } from "../../../../store/reducers/root";
import { OnboardingInintState } from "../../../../store/reducers/onboarding";
import { DEFAULT_WORK_DAYS } from "../../../../utils/globalConstants";
import { OnboardInintState } from "../../../../store/reducers/onboard";

function Index(props: StepsProps) {
  const { formik, step, setStep } = props;
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedStoreFront, setSelectedStoreFront] = useState<string>("");
  const { shopName, token }: RootInitStateInterface = useSelector((state: any) => state.root);
  const { shopProviderId, apiKey, password }: OnboardingInintState = useSelector(
    (state: any) => state.onboarding
  );
  const { paymentPlan }: OnboardInintState = useSelector((state: any) => state.onboard);
  console.log({ shopName, token }, "shopName, token");
  useEffect(() => {
    if (selectedStoreFront) {
      formik.setFieldValue("providerName", selectedStoreFront);
    }
  }, [selectedStoreFront]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(formik.values.providerName, "formik.values.providerName");
    if (formik.values.providerName) {
      setSelectedStoreFront(formik.values.providerName);
    }
  }, [formik.values.providerName]); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleConnect() {
    try {
      dispatch({ type: "ONBOARDING_LOADER", payload: true });
      console.log(checkOrAddPostfix(shopName), "values");
      setOpenDialog(false);
      const shopExist = await ShopExists(checkOrAddPostfix(shopName), ShopType.Shopify);

      if (shopExist && shopExist["shopExists"]) {
        setOpenDialog(true);
        ErrorToaster("Shop already exist");
        console.log("shop already exist", shopExist);
        dispatch({ type: "ONBOARDING_LOADER", payload: false });
      } else {
        setOpenDialog(false);
        let redirectURL = process.env.REACT_APP_SHOPIFY_REDIRECT_URL ?? "";
        dispatch({ type: "ONBOARDING_LOADER", payload: false });
        redirectURL = redirectURL + `?shop=${checkOrAddPostfix(shopName)}`;
        window.location.href = redirectURL;
      }
      dispatch({ type: "ONBOARDING_LOADER", payload: false });
    } catch (e) {
      dispatch({ type: "ONBOARDING_LOADER", payload: false });
    }
  }

  useEffect(() => {
    if (shopName && token) {
      initiate();
    }
  }, [shopName, token]); // eslint-disable-line react-hooks/exhaustive-deps

  async function initiate() {
    try {
      dispatch({ type: "ONBOARDING_LOADER", payload: true });
      let verified = await verifyShopAuth(checkOrAddPostfix(shopName), token, ShopType.Shopify);
      console.log(verified, "verified in shop onboading");
      if (verified) {
        const response = await initiateOnboarding(
          shopName,
          paymentPlan as PricingPlan,
          undefined,
          undefined,
          ShopType.Shopify
        );
        console.log(response, "initiate onboarding response");

        const data: OnboardingInput = {
          fulfillmentCenters: parseFcList(response.fulfilmentServices), // "fulfillmentsData",
          invitedUsers: [], // allInvitedUsers,
          registeringUser: null, // onbooardingUsers,
          shopifyUrl: `${shopName}`, //BE should complete the url from shopType
          shopifyAccessKey: apiKey,
          shopifyPassword: password,
          shopProviderInternalId: shopProviderId,
          planAlias: paymentPlan as PricingPlan,
          shopType: ShopType.Shopify,
        };
        let redirectURL = await finalizeOnboarding(data);
        await markShopActive(shopName, ShopType.Shopify);
        dispatch({ type: "SET_ONBOARD_SHOPNAME", payload: response.shopifyURL });
        dispatch({ type: "CLEAR_QUERY_PARAMS" });
        dispatch({ type: "SET_SHOP_TYPE", payload: ShopType.Shopify });
        dispatch({ type: "CHANGE_STEP_COUNT", payload: (step || 0) + 1 });

        console.log(redirectURL, "redirectURL");
        localStorage.setItem("redirectURL", redirectURL);
        dispatch({ type: "ONBOARDING_LOADER", payload: false });
        setTimeout(() => {
          window.location.href = redirectURL;
        }, 100);
      }
    } catch (err) {
      dispatch({ type: "ONBOARDING_LOADER", payload: false });
    }
  }

  function parseFcList(fcList: ShopFc[]) {
    const fcData = [];
    const fcItem = {
      warehouseName: "New Fulfillment",
      contractPercent: 99,
      days: DEFAULT_WORK_DAYS,
      delayDuration: 0,
      delayDurationType: DurationType.Minutes,
      delayOrders: false,
      fromTime: new Date(new Date(new Date().setHours(7)).setMinutes(0)),
      toTime: new Date(new Date(new Date().setHours(17)).setMinutes(0)),
      timezone: 0,
      scorecardSchedules: null,
      userId: -1,
      handle: "",
      provider_internal_Id: 0,
      ordersBefore: 0,
      ordersAfter: 0,
      cutoffDate: new Date(new Date(new Date().setHours(17)).setMinutes(0)),
      route: { addresses: [], priority: -1 },
      routingSoftware: "",
      address: "",
      status: "ACTIVE",
      holidays: [],
      orderAccuracyPercent: 99,
      prepDurationTime: 0,
      prepDurationType: DurationType.Days,
      shippingWindowType: ShippingWindowType.Basic,
      shippingPeriod: 0,
      shippingPeriodType: DurationType.Days,
      dayOfShipping: DayOfWeek.Fri,
      salesChannelId: 0,
    };
    if (fcList.length) {
      fcList.forEach((fc: ShopFc) => {
        fcData.push({
          ...fcItem,
          warehouseName: fc?.name?.toString() || "",
          address: fc?.address || "",
          handle: fc?.handle || "",
          provider_internal_Id: fc.id || 0,
        });
      });
    } else {
      fcData.push({
        ...fcItem,
      });
    }

    return fcData;
  }

  return (
    <>
      <Stepper setStep={setStep} setOpenDialog={setOpenDialog} formik={formik} step={step || 0}>
        <div className="p-7">
          <Title style={{ marginBottom: 19 }}>
            <>Select your storefront</>
          </Title>
          <List className="p-7 overflow-auto" style={{ height: 324 }}>
            {providerList.map((provider) => (
              <ListItem
                key={provider.value}
                sx={{ cursor: "pointer", paddingLeft: "0px", marginTop: 2, userSelect: "none" }}
                // onClick={() => setSelectedStoreFront(provider.value)}
                onClick={() => setSelectedStoreFront(provider.value)}
                secondaryAction={
                  <Radio
                    checked={
                      (selectedStoreFront && selectedStoreFront === provider.value) ||
                      formik.values.provider === provider.value
                        ? true
                        : false
                    }
                    size="small"
                  />
                }
              >
                <Avatar sx={{ marginRight: 1 }}>
                  <img src={Image} alt="Avatar" />
                </Avatar>
                <ListItemText primary={provider.label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Stepper>
      {openDialog && step === 2 && (
        <ShopifyDialog
          handleConnect={handleConnect}
          formik={formik}
          open={openDialog}
          setOpen={setOpenDialog}
        />
      )}
    </>
  );
}

export default Index;

function checkOrAddPostfix(shopName: string) {
  if (shopName.includes(".myshopify.com")) {
    return shopName;
  }

  return `${shopName}.myshopify.com`;
}

const providerList = [
  { label: "Shopify", name: "storefront", value: "shopify" },
  { label: "Skubana", name: "storefront", value: "skubana" },
  { label: "Cin7", name: "storefront", value: "cin7" },
  { label: "Other", name: "storefront", value: "other" },
];
