import { PricingPlan } from "../../generated/graphql";
import { types } from "../types";

const { CHANGE_FORM, RESET_ONBOARD } = types;

const initState: OnboardInintState = {
  // 0 step
  email: "",
  password: "",
  confirmPassword: "",
  terms: false,

  // 1 step
  firstName: "",
  lastName: "",
  businessName: "",

  // 2 step
  providerName: "shopify",

  // 9 step
  provider: "brand",

  // 10 step
  paymentPlan: PricingPlan.Plus,
};

export const onboardReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    case CHANGE_FORM:
      return {
        ...state,
        ...(action.payload || {}),
      };
    case RESET_ONBOARD:
      return {
        // 0 step
        email: "",
        password: "",
        confirmPassword: "",
        terms: false,

        // 1 step
        firstName: "",
        lastName: "",
        businessName: "",

        // 2 step
        providerName: "shopify",

        // 9 step
        provider: "brand",

        // 10 step
        paymentPlan: PricingPlan.Plus,
      };
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface OnboardInintState {
  // 0 step

  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;

  // 1 step
  firstName: string;
  lastName: string;
  businessName: string;

  // 2 step
  providerName: string;

  // 9 step
  provider: string;

  // 10 step
  paymentPlan: PricingPlan;
}
