import loadable from "@loadable/component";
import { AlertColor, Box, createTheme, LinearProgress, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import AuthAfter from "./AuthAfter";
import AuthBefore from "./AuthBefore";
import ShopifyOTP from "./Components/Dashboard/ShopifyOTP";
import ForgotPassword from "./Components/Onboarding/ForgotPassword";
import UserLogin from "./Components/Onboarding/UserLogin";
import NewLogin from "./Components/Onboarding/NewLogin";
import Signup from "./Components/NewHome/Signup/Index";
import Logins from "./Components/NewHome/Login/Index";
import Dashboards from "./Components/NewHome/Login/Index";
import UserWelcome from "./Components/Onboarding/UserWelcome";
import { OnboardingUserInput, Role } from "./generated/graphql";
import background from "./Resources/misc/background.png";
import { SuccessToaster } from "./toast";
import { getCurrentAuthenticatedUser } from "./utils/AuthWrapper";
import { fetchUserByEmailFromDB } from "./utils/providerServices";
import { theme as newTheme } from "./theme";
import SkubanaIntegration from "./Components/Onboarding/Apps/Skubana";
import Reports from "./Components/Dashboard/scorecarding/Reports/index";
import * as amplitude from "@amplitude/analytics-browser";
import { USER_EMAIL } from "./utils/const";
import SplashLoader from "./splashLoader";
import { theme as defaultTheme } from "./NewTheme";

amplitude.init(process.env.REACT_APP_AMP_BROWSER_KEY);
const DashboardAssembly = loadable(() => import("./Components/Dashboard/DashboardAssembly"));
const OnboardContainer = loadable(() => import("./Components/Onboarding/OnboardingContainer"));

function App() {
  const dispatch = useDispatch();
  const [fetchedUser, setFetchedUser] = useState<OnboardingUserInput | undefined>(undefined);
  const { onboarding }: any = useSelector((state: any) => state);
  const [path, setPath] = useState("");
  const [loaded, setLoaded] = useState(false);

  const qShop = useRef("");
  const qToken = useRef("");
  const shopType = useRef("");
  const skubanaCid = useRef("");
  const skubanaCode = useRef("");
  useEffect(() => {
    const params = window.location.href.split("?")[1];
    if (params) {
      const query = params.split("&");
      query.forEach((q) => {
        const val = q.split("=");
        if (val[0] === "notif") {
          SuccessToaster(val[1]);
        } else if (val[0] === "shop") {
          qShop.current = val[1];
          dispatch({
            type: "QUERY_PARAM_SHOP_NAME",
            payload: val[1],
          });
        } else if (val[0] === "otp") {
          qToken.current = val[1];
          dispatch({
            type: "QUERY_PARAM_TOKEN_NAME",
            payload: val[1],
          });
        } else if (val[0] === "type") {
          shopType.current = val[1];
          dispatch({ type: "SET_SHOP_TYPE", payload: val[1] });
        } else if (val[0] === "cid") {
          skubanaCid.current = val[1];
          dispatch({
            type: "QUERY_PARAM_SKUBANA_CID",
            payload: val[1],
          });
        } else if (val[0] === "code") {
          skubanaCode.current = val[1];
          dispatch({
            type: "QUERY_PARAM_SKUBANA_CODE",
            payload: val[1],
          });
        } 
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loaded && !["/auth", "/skubana"].includes(window.location.pathname)) {
      async function loadApp(): Promise<void> {
        try {
          let user = await getCurrentAuthenticatedUser();
          if (user !== null) {
            let userData: OnboardingUserInput = {
              comp_name: user.companyName,
              email: user.email,
              created_at: "",
              cust_id: 0,
              role: Role.Admin,
              id: -1,
              status: "ACTIVE",
              deleted_at: null,
              firstName: user.firstName,
              lastName: user.lastName,
            };
            try {
              let res = await fetchUserByEmailFromDB();
              if (res !== null && user !== null) {
                setFetchedUser({
                  comp_name: user.companyName,
                  email: user.email,
                  created_at: res.createdAt,
                  cust_id: res.customerId,
                  role: res.role,
                  id: res.id,
                  status: "ACTIVE",
                  deleted_at: null,
                  firstName: res.firstName,
                  lastName: res.lastName,
                });
                if (qShop.current !== "" && qToken.current !== "" && qShop && qToken) {
                  isShopInprocess();
                }
                setPath("/dashboard");
                setLoaded(true);
              } else {
                // if user does not exit in db then go to onboarding
                setFetchedUser(userData);
                setPath(`/onboarding${window.location.search}`);
                setLoaded(true);
              }
            } catch (error) {
              // if user does not exit in db then go to onboarding
              console.error(error);
              setFetchedUser(userData);
              setPath(`/onboarding${window.location.search}`);
              setLoaded(true);
            }
          } else {
            // User redirected to sign in
            setPath(`/${window.location.search}`);
            setLoaded(true);
          }
        } catch (e) {
          // User redirected to sign in
          if (window.location.href.includes("?")) {
            setPath(`/?${window.location.href.split("?")[1]}`);
          } else if (window.location.href.split("#/")[1] === "signup") {
            setPath(`/signup`);
          } else {
            setPath(`/${window.location.search}`);
          }
          setLoaded(true);
        }
      }

      loadApp();
    } else if (window.location.pathname === "/auth") {
      setPath("/auth");
      setLoaded(true);
    } else if (window.location.pathname === "/skubana") {
      setPath("/skubana");
      setLoaded(true);
    }
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  function isShopInprocess() {
    if (qShop.current !== "" && qToken.current !== "" && qShop && qToken) {
      dispatch({
        type: "SELECTED_TAB",
        payload: 6,
      });
    }
  }

  useEffect(() => {
    if (
      ((fetchedUser && fetchedUser.email) || onboarding.onboardingUser) &&
      localStorage.getItem(USER_EMAIL)
    ) {
      const email = fetchedUser ? fetchedUser.email : onboarding.onboardingUser.email;
      console.log(email, "in change effect");
      localStorage.setItem("onboard_" + email, JSON.stringify(onboarding));
    }
  }, [onboarding]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ width: "100%" }}>
      {!loaded && <LinearProgress />}
      {loaded && <LoadRoutes path={path} user={fetchedUser} isShopInprocess={isShopInprocess} />}
    </Box>
  );
}

function LoadRoutes(props: {
  path?: string;
  user?: OnboardingUserInput;
  isShopInprocess: () => void;
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SET_SELECTED_FC", payload: [] });
    dispatch({ type: "SET_SHOP_STATUS", payload: "init" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mx-auto" style={{ maxWidth: 1600 }}>
      {props.path === "/auth" ? (
        <BrowserRouter>
          <Route path="/auth">
            <ShopifyOTP />
          </Route>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Switch>
            <AuthAfter path="/about">
              <About />
            </AuthAfter>
            <AuthAfter path={`/onboarding`}>
              <OnboardContainer />
            </AuthAfter>
            <AuthAfter path="/dashboard">
              <DashboardAssembly />
            </AuthAfter>
            <AuthAfter path="/skubana">
              <SkubanaIntegration />
            </AuthAfter>
            <Route path="/welcome">
              <Welcome />
            </Route>
            <Route path="/reports">
              <Reports />
            </Route>
            <AuthBefore path="/newlogin">
              <Home isShopInprocess={props.isShopInprocess} useNewLogin={true} />
            </AuthBefore>
            <AuthBefore path="/signup">
              <Suspense fallback={<SplashLoader />}>
                <Signup />
              </Suspense>
            </AuthBefore>
            <AuthBefore path="/">
              <Suspense fallback={<SplashLoader />}>
                <ThemeProvider theme={defaultTheme}>
                  <Logins />
                </ThemeProvider>
              </Suspense>
            </AuthBefore>
            <Route path="/dashboards">
              <Suspense fallback={<SplashLoader />}>
                <ThemeProvider theme={defaultTheme}>
                  <Dashboards />
                </ThemeProvider>
              </Suspense>
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
}

function Home(props: { useNewLogin?: boolean; isShopInprocess: () => void }) {
  const classes = useStyles();
  const location = useLocation();
  const [showVerif, setShowVerif] = useState<boolean>(false);
  const [generatedPass, setGeneratedPass] = useState<string>("");
  const [landingUser, setLandingUser] = useState<string>("");
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [openAlertMsg, setOpenAlertMsg] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");

  useEffect(() => {
    let generatedPassword: string[] = [];
    let userEmail: string[] = [];
    let verifCode: string[] = [];
    let planFromSite: string[] = [];
    let isAdvanceView: string[] = [];

    // to handle multiple parameters. e.g. localhost?param1=value1&param2=value2&param3=value3, param2 and param3 will be in search.
    if (location.search) {
      generatedPassword = new URLSearchParams(location.search).getAll("generated");
      userEmail = new URLSearchParams(location.search).getAll("user");
      verifCode = new URLSearchParams(location.search).getAll("code");
      planFromSite = new URLSearchParams(location.search).getAll("plan");
      isAdvanceView = new URLSearchParams(location.search).getAll("view");
    } else if (location.hash) {
      let generated = getParameterByName("generated", location.hash);
      if (generated) generatedPassword.push(generated);

      let user = getParameterByName("user", location.hash);
      if (user) userEmail.push(user);

      let code = getParameterByName("code", location.hash);
      if (code) verifCode.push(code);

      let plan = getParameterByName("plan", location.hash);
      if (plan) planFromSite.push(plan);

      let view = getParameterByName("view", location.hash);
      if (view) isAdvanceView.push(view);
    }

    if (generatedPassword.length > 0 && userEmail.length > 0) {
      //Values are present for landing user
      setGeneratedPass(generatedPassword[0]);
      setLandingUser(userEmail[0]);
      setShowVerif(true);
      setShowForgotPassword(true);
    } else if (verifCode.length > 0) {
      setVerifyCode(verifCode[0]);
      setShowForgotPassword(true);
    } else if (planFromSite.length > 0) {
      localStorage.setItem("capablPlan", planFromSite[0]);
    } else if (isAdvanceView.length > 0) {
      //land it on advance view
      localStorage.setItem("view", "Advance");
    }
  }, [location.search, location.hash]);

  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);

  /**
   * Gets query string parameter from the URL.
   */
  function getParameterByName(name: string, url: string) {
    name = name.replace(/[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  /**
   * Show forgot password Modal
   */
  function showForgotPass() {
    setShowForgotPassword(true);
  }

  /**
   * Hide forgot password Modal
   */
  function hideForgotPass() {
    setShowForgotPassword(false);
    if (landingUser !== "") {
      //Password reset has been performed reset the top url to base site url
      window.location.href = window.location.href.split("?")[0];
    }
  }
  /**
   * Handle the Forgot Password flow
   */
  function handleForgotPass() {
    setAlertMessage("Reset Successfull! Please check your email for password reset link");
    setAlertSeverity("success");
    setOpenAlertMsg(true);
    setShowForgotPassword(false);
  }

  function handleResetSuccessfull() {
    setAlertMessage("Updated Successfully! Please login with your new password");
    setAlertSeverity("success");
    setOpenAlertMsg(true);
    setShowForgotPassword(false);
    if (verifyCode !== "") {
      //Password reset has been performed reset the top url to base site url
      window.location.href = window.location.href.split("?")[0];
    }
  }

  function showAlert(message: string, severity: AlertColor, open: boolean) {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setOpenAlertMsg(open);
  }

  function hideAlert() {
    setOpenAlertMsg(false);
  }

  return (
    <div className={classes.mainContainer}>
      {props.useNewLogin ? (
        <ThemeProvider theme={newTheme}>
          <div className={classes.centerBox}>
            <NewLogin
              showForgotPass={showForgotPass}
              openAlertMsg={openAlertMsg}
              alertSeverity={alertSeverity}
              showAlert={showAlert}
              hideAlert={hideAlert}
              alertMessage={alertMessage}
              isShopInprocess={props.isShopInprocess}
            />
          </div>
        </ThemeProvider>
      ) : (
        <>
          <div className={classes.leftBox}></div>
          <div className={classes.rightBox}>
            <UserLogin
              showForgotPass={showForgotPass}
              openAlertMsg={openAlertMsg}
              alertSeverity={alertSeverity}
              showAlert={showAlert}
              hideAlert={hideAlert}
              alertMessage={alertMessage}
              isShopInprocess={props.isShopInprocess}
            />
          </div>
        </>
      )}

      <ForgotPassword
        handleResetSuccess={handleResetSuccessfull}
        verificationCode={verifyCode}
        handleForgotPassword={handleForgotPass}
        user={landingUser}
        generatedPass={generatedPass}
        showVerif={showVerif}
        showModal={showForgotPassword}
        onModalHide={hideForgotPass}
        onPasswordForgotDone={hideForgotPass}
      />
      {/* Snackbar */}
    </div>
  );
}

function Welcome() {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div className={classes.leftBox}></div>
      <div className={classes.rightBox}>
        <UserWelcome />
      </div>
    </div>
  );
}

function About() {
  return <h2>About</h2>;
}

export default App;

const theme = createTheme();
const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    display: "flex",
  },
  rightBox: {
    backgroundColor: "#2C1D4F",
    width: "65%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0%",
    },
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "35%",
    padding: "80px 0px",
  },
  leftBox: {
    width: "35%",
    [theme.breakpoints.down("md")]: {
      width: "0%",
    },
    backgroundImage: `url(${background})`,
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
  },
  centerBox: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    display: "flex",
    marginTop: "4%",
  },
});
