import { Button } from "@mui/material";

interface ButtonProps {
  variant?: "text" | "outlined" | "contained" | undefined;
  className?: any;
  size?: "small" | "medium" | "large" | undefined;
  label?: string;
  children?: any;
  onClick?: () => void;
  colorName?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  fullWidth?: boolean;
}

const ButtonControl = (props: ButtonProps) => {
  return (
    <Button
      className={props.className || ""}
      color={props.colorName || "primary"}
      variant={props.variant || "contained"}
      size={props.size || "medium"}
      onClick={props.onClick}
      fullWidth={props.fullWidth || false}
    >
      {props.label}
    </Button>
  );
};

export default ButtonControl;
