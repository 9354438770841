import { Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  attachCardForCustomer,
  sendOnboardingScheduleMail,
} from "../../../../utils/providerServices";
import { OnboardingInintState } from "../../../../store/reducers/onboarding";
import { SuccessToaster } from "../../../../toast";

function Index(props: StepperProps) {
  const { children, formik, step, setOpenDialog } = props;
  const { onboardingUser }: OnboardingInintState = useSelector((state: any) => state.onboarding);
  const dispatch = useDispatch();

  console.log(onboardingUser, "onboardingUser in paymnet");

  return (
    <div className={`flex flex-col justify-between`} style={{ minHeight: 590 }}>
      {children}
      <div className="flex flex-row justify-between items-center px-7 py-5">
        <Button
          variant="contained"
          onClick={async () => {
            if (step && step === 1 && formik.values.paymentPlan && onboardingUser) {
              try {
                dispatch({ type: "ONBOARDING_LOADER", payload: true });
                await attachCardForCustomer(
                  onboardingUser.comp_name ?? "",
                  onboardingUser.email ?? "",
                  onboardingUser.firstName ?? "",
                  onboardingUser.lastName ?? "",
                  formik.values.paymentPlan,
                  { clientSecret: "", coupon: "", customerId: "" }
                );
                dispatch({ type: "ONBOARDING_LOADER", payload: false });
                dispatch({ type: "CHANGE_STEP_COUNT", payload: step + 1 });
              } catch (e) {
                dispatch({ type: "ONBOARDING_LOADER", payload: false });
              }
            }
            if (step && step === 2 && formik.values.providerName === "shopify") {
              setOpenDialog && setOpenDialog(true);
            } else if (step && step === 2 && formik.values.providerName !== "shopify") {
              await sendOnboardingScheduleMail(
                "",
                `${onboardingUser?.email}`,
                `${onboardingUser?.firstName} ${onboardingUser?.lastName}`
              );
              SuccessToaster("Please check your email for onboarding scheduling");
            } else {
              dispatch({ type: "CHANGE_STEP_COUNT", payload: step + 1 });
            }
          }}
          startIcon={<ArrowForward />}
          style={{ width: "100%" }}
          color="primary"
        >
          Save & Continue
        </Button>
      </div>
    </div>
  );
}

export default Index;

interface StepperProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  children: React.ReactElement;
  step: number;
  formik: any;
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}
