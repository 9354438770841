import { OutlinedInput, InputAdornment, InputLabel, FormControl } from "@mui/material";

interface InputProps {
  value?: string;
  className?: string;
  id?: string;
  icon?: any;
  label?: string;
  isShowPassword?: boolean;
  fullWidth?: boolean;
  type?: "text" | "password";
  adornmentPosition?: "start" | "end";
  formControlvariant?: "standard" | "outlined" | "filled";
  onChange?: (event: any) => void;
  onKeyDown?: (event: any) => void;
}

const Input = (props: InputProps) => {
  const fieldType = props.isShowPassword ? "text" : "password";

  let adornmentPosition: "start" | "end" = "end"; // Default position is at the end.
  if (props.adornmentPosition === "start") {
    adornmentPosition = "start";
  }

  return (
    <FormControl variant={props.formControlvariant || "outlined"}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <OutlinedInput
        className={props.className || ""}
        fullWidth={props.fullWidth || false}
        id={props.id}
        type={fieldType}
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        endAdornment={
          props.icon && <InputAdornment position={adornmentPosition}>{props.icon}</InputAdornment>
        }
      />
    </FormControl>
  );
};

export default Input;
