import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import {
  onboardingReducer,
  rootReducer,
  dashboardReducer,
  biReducer,
  advanceReducer,
  settingReducer,
  legendReducer,
  onboardReducer
} from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import LogRocket from "logrocket";
import { reportViewReducer } from "./reducers/reportview";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["root", "onboarding", "legends", "report", 'onboard'],
};

const reducers = combineReducers({
  bi: biReducer,
  legends: legendReducer,
  advance: advanceReducer,
  onboarding: onboardingReducer,
  root: rootReducer,
  dashboard: dashboardReducer,
  setting: settingReducer,
  report: reportViewReducer,
  onboard: onboardReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store: Store<any, any> = createStore(
  persistedReducer,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(thunk, LogRocket.reduxMiddleware()))
);
const persistor = persistStore(store);
export { store, persistor };
