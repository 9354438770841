import { Button, Typography } from "@mui/material";
import { StepsProps } from "../../styles";
import Title from "../Title/Index";
import { ArrowForward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_STATUS } from "../../../../utils/const";
import { OnboardingInintState } from "../../../../store/reducers/onboarding";
import { PlansPrice } from "../../../../Helpers/Interfaces/PaymentPlans";
import { OnboardInintState } from "../../../../store/reducers/onboard";

function Index(props: StepsProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state: OnboardInintState = useSelector((state: any) => state.onboard);
  const { onboardingUser }: OnboardingInintState = useSelector((state: any) => state.onboarding);

  return (
    <div className="p-7">
      <Title style={{ marginBottom: 19 }}>
        <>Confirmation</>
      </Title>
      <div className="grow flex flex-col justify-between" style={{ height: 450 }}>
        <div className="flex flex-row justify-between items-center">
          <Typography variant="subtitle1">First Name</Typography>
          <Typography variant="body1">{onboardingUser?.firstName}</Typography>
        </div>
        <div className="flex flex-row justify-between items-center">
          <Typography variant="subtitle1">Last Name</Typography>
          <Typography variant="body1">{onboardingUser?.lastName}</Typography>
        </div>
        <div className="flex flex-row justify-between items-center">
          <Typography variant="subtitle1">Email</Typography>
          <Typography variant="body1">{onboardingUser?.email}</Typography>
        </div>
        <div className="flex flex-row justify-between items-center">
          <Typography variant="subtitle1">Business Name</Typography>
          <Typography variant="body1">{onboardingUser?.comp_name}</Typography>
        </div>
        <div className="flex flex-row justify-between items-center">
          <Typography variant="subtitle1">Integration</Typography>
          <Typography variant="body1">{state.providerName}</Typography>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col items-start">
            <Typography variant="subtitle1">Plan</Typography>
            <Typography variant="caption">{PlansPrice[state.paymentPlan]}/monthly</Typography>
          </div>
          <Typography variant="body1">{getSelectedPrice(state.paymentPlan)}</Typography>
        </div>
        <Button
          onClick={() => {
            localStorage.setItem(USER_STATUS, JSON.stringify("active"));
            history.push("/dashboard");
            dispatch({ type: "RESET_ONBOARDING" });
            dispatch({ type: "RESET_ONBOARD" });
            dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
            dispatch({ type: "CLEAR_QUERY_PARAMS" });
          }}
          startIcon={<ArrowForward />}
          color="primary"
        >
          Finish
        </Button>
      </div>
    </div>
  );
}

export default Index;

const getSelectedPrice = (plan: any) =>
  plan === "LITE"
    ? "Free"
    : plan === "STARTER"
    ? "Basic"
    : plan === "PLUS"
    ? "Plus"
    : plan === "ULTRA"
    ? "Premium"
    : "";
