import {
  Alert,
  AlertColor,
  Box,
  Snackbar,
  IconButton,
  Typography,
  Container,
} from "@mui/material";
import Link from "@mui/material/Link";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import capablLogo from "../../Resources/onboarding/capabl-bright-icon.png";
import { makeStyles } from "@mui/styles";
import { Loader } from "../../loading";
import ButtonControl from "../Common/Input/Button";
import Input from "../Common/Input/Input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { userSignIn } from "../../utils/signInHelper";


export interface loginInfo {
  redirectUri?: string;
  showForgotPass: () => void;
  openAlertMsg: boolean;
  alertSeverity: string;
  showAlert: (message: string, severity: AlertColor, open: boolean) => void;
  hideAlert: () => void;
  alertMessage: string;
  isShopInprocess: () => void;
}

function NewLogin(loginProps: loginInfo) {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [loadings, setLoadings] = useState<boolean>(false);
  const [password, setPassword] = useState<string>(""),
    [emailAddress, setEmailAddress] = useState<string>("");
  const history = useHistory();
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      {loadings && <Loader />}
      <Paper elevation={4}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={loginProps.hideAlert}
          open={loginProps.openAlertMsg}
          autoHideDuration={3000}
        >
          <Alert sx={{ width: '550px' }} severity={loginProps.alertSeverity as AlertColor}>{loginProps.alertMessage}</Alert>
        </Snackbar>
        <Card>
          {/* <CardMedia component="img" image={capablLogo} height="47" width="214" alt="capabl logo"  /> */}
          {/* <CardHeader>
            <Typography variant="h5" component="div">
              Sign in to your account
            </Typography>
          </CardHeader> */}
          <CardContent>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="center">
                <img src={capablLogo} width="214px" height="47px" alt="" />
              </Stack>
              <Stack direction="row" justifyContent="center">
              <Typography variant="h5">
                Sign in to your account
              </Typography>
              </Stack>
              <Stack spacing={3} className="p-5">
              <Input
                label="Email"
                type="text"
                value={emailAddress}
                onChange={setEmail}
                onKeyDown={keyDown}
                isShowPassword={true}
                fullWidth={true}
              />
              <Input
                label="Password"
                type="password"
                id="outlined-adornment-password"
                value={password}
                fullWidth={true}
                isShowPassword={isShowPassword}
                onChange={setUserPassword}
                onKeyDown={keyDown}
                icon={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={setShowPassword}
                    edge="end"
                  >
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
              <Stack direction="row" justifyContent="start" >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="I accept the terms and conditions"
                />
              </FormGroup>
              </Stack>
              <ButtonControl
                variant="contained"
                className={classes.signInButton}
                size="large"
                label="Log In"
                onClick={userSignin}
                fullWidth={true}
              />
              </Stack>
              <Stack direction="row" justifyContent="end" >
              <Link
                component="button"
                underline="none"
                color="info"
                onClick={() => loginProps.showForgotPass()}
              >
                <Typography variant="body2">Forgot password?</Typography>
              </Link>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Paper>
      <Box className="flex justify-center mt-5">
        <Link component="button" onClick={redirectToSignup}>
          <Typography variant="body2">Create a new account</Typography>
        </Link>
      </Box>
    </Container>
  );

  /**
   * redirect user to signup page
   */
  function redirectToSignup() {
    history.push("/signup" + history.location.search);
  }

  /**
   * set email address
   */
  function setEmail(event: { target: { value: any } }) {
    setEmailAddress(event.target.value);
  }

  /**
   * set password
   */
  function setUserPassword(event: { target: { value: any } }) {
    setPassword(event.target.value);
  }

  /**
   * toggle password visibility
   */
  function setShowPassword() {
    setIsShowPassword(!isShowPassword);
  }

  /**
   * if user hits enter, sign in the user.
   */
  function keyDown(event: { key: string }) {
    if (event.key === "Enter") {
      userSignin();
    }
  }

  /**
   * sign in the user
   */
  async function userSignin() {
    await userSignIn({
      emailAddress: emailAddress,
      password: password,
      setLoadings: setLoadings,
      loginProps: {
        showAlert: loginProps.showAlert,
        isShopInprocess: loginProps.isShopInprocess,
      },
      history: { push: history.push, location: { search: history.location.search}}
    });
  }
}

export default NewLogin;

const useStyles = makeStyles({
  signInButton: {
    borderRadius: "26px !important",
  },
});
