import { Shop } from "../generated/graphql";

export function decimalParser(value: number, toFix?: number) {
  return value
    ? Number.isInteger(value)
      ? value + ""
      : Number(value).toFixed(toFix || 2) + ""
    : "0";
}

export function activePlan(shopList: Shop[], activeShop: string) {
  const activePlan = shopList.find((shop: Shop) => shop.domain === activeShop);

  return (activePlan && activePlan["pricingPlan"]) || "";
}
