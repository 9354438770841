export const types = {
  // #region root level props types
  SHOPS_LIST: "SHOPS_LIST",
  SHOPS_LIST_FAILED: "SHOPS_LIST_FAILED",
  ACTIVE_SHOP_DOMAIN: "ACTIVE_SHOP_DOMAIN",
  LOGOUT_ROOT: "LOGOUT_ROOT",
  FC_LIST_FAILED: "FC_LIST_FAILED",
  ACTIVE_FC_LIST: "ACTIVE_FC_LIST",
  ACTIVE_USER_ROLE: "ACTIVE_USER_ROLE",
  ACTIVE_USER_FAILED: "ACTIVE_USER_FAILED",
  ACTIVE_USER_FULLNAME: "ACTIVE_USER_FULLNAME",
  QUERY_PARAM_SHOP_NAME: "QUERY_PARAM_SHOP_NAME",
  QUERY_PARAM_TOKEN_NAME: "QUERY_PARAM_TOKEN_NAME",
  QUERY_PARAM_SHOP_TYPE: "QUERY_PARAM_SHOP_TYPE",
  CLEAR_QUERY_PARAMS: "CLEAR_QUERY_PARAMS",
  SELECTED_TAB: "SELECTED_TAB",
  SET_IS_ADVANCE_VIEW: "SET_IS_ADVANCE_VIEW",
  IS_THIS_DIRECT_SHOP: "IS_THIS_DIRECT_SHOP",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  UPDATE_INIT_SCORE_CARD: "UPDATE_INIT_SCORE_CARD",
  // #endregion

  // #region dashboard view types
  DASHBOARD_VIEW_DATA_LOADING: "DASHBOARD_VIEW_DATA_LOADING",
  DASHBOARD_VIEW_DATA: "DASHBOARD_VIEW_DATA",
  DASHBOARD_VIEW_DATA_FAILED: "DASHBOARD_VIEW_DATA_FAILED",
  LAND_PAGE_WEEKLY_ARRAY: "LAND_PAGE_WEEKLY_ARRAY",
  LAND_PAGE_TOTAL_SPAN: "LAND_PAGE_TOTAL_SPAN",
  LAND_PAGE_SELECTED_FCS: "LAND_PAGE_SELECTED_FCS",
  DASHBOARD_SPAN_SIX_MONTH: "DASHBOARD_SPAN_SIX_MONTH",
  DASHBOARD_MONTHLY_ARRAY: "DASHBOARD_MONTHLY_ARRAY",
  DASHBOARD_MAP_DATA: "DASHBOARD_MAP_DATA",
  DASHBOARD_MAP_DATA_LOADING: "DASHBOARD_MAP_DATA_LOADING",
  DASHBOARD_MAP_DATA_FAILED: "DASHBOARD_MAP_DATA_FAILED",
  DASHBOARD_MONTHS_ARRAY: "DASHBOARD_MONTHS_ARRAY",
  // #region

  // #region admin settings types
  FC_LIST: "FC_LIST",
  SETTING_LOADER: "SETTING_LOADER",
  ALL_USERS_IN_SETTING: "ALL_USERS_IN_SETTING",
  ALL_SALES_CHANNELS: "ALL_SALES_CHANNELS",
  LOADER_FAILED: "LOADER_FAILED",
  FC_LIST_SETTING: "FC_LIST_SETTING",
  FC_LIST_SETTING_FAILED: "FC_LIST_SETTING_FAILED",
  RULES_LAODER: "RULES_LAODER",
  RULES_LAODER_FAILED: "RULES_LAODER_FAILED",
  ROUTE_RULES_LIST: "ROUTE_RULES_LIST",
  ROUTE_SKU_RULES_LIST: "ROUTE_SKU_RULES_LIST",
  ROUTE_RULES_LIST_FAILED: "ROUTE_RULES_LIST_FAILED",
  EXCLUDE_SKU_RULES_LIST: "EXCLUDE_SKU_RULES_LIST",
  EXCLUDE_SKU_RULES_LIST_FAILED: "EXCLUDE_SKU_RULES_LIST_FAILED",
  SLA_OVERWRITING_RULES_LIST: "SLA_OVERWRITING_RULES_LIST",
  SLA_OVERWRITING_RULES_LIST_FAILED: "SLA_OVERWRITING_RULES_LIST_FAILED",
  SLA_OVERWRITING_SKU_RULES_LIST: "SLA_OVERWRITING_SKU_RULES_LIST",
  SALES_CHANNEL_RULES_LIST: "SALES_CHANNEL_RULES_LIST",
  SLA_OVERWRITING_SKU_RULES_LIST_FAILED: "SLA_OVERWRITING_SKU_RULES_LIST_FAILED",
  ASSIGN_TAG_RULES_LIST: "ASSIGN_TAG_RULES_LIST",
  ASSIGN_TAG_RULES_LIST_FAILED: "ASSIGN_TAG_RULES_LIST_FAILED",
  EXCLUDE_RULES_LIST: "EXCLUDE_RULES_LIST",
  EXCLUDE_RULES_LIST_FAILED: "EXCLUDE_RULES_LIST_FAILED",
  CLEAR_RULES: "CLEAR_RULES",
  SCHEDULE_SCORECARD_LIST: "SCHEDULE_SCORECARD_LIST",
  SCHEDULE_SCORECARD_LIST_FAILED: "SCHEDULE_SCORECARD_LIST_FAILED",
  SCORECARD_USERS_LIST: "SCORECARD_USERS_LIST",
  SCORECARD_USERS_LIST_FAILED: "SCORECARD_USERS_LIST_FAILED",
  FC_RIGHTS_LIST: "FC_RIGHTS_LIST",
  FC_RIGHTS_LIST_FAILED: "FC_RIGHTS_LIST_FAILED",
  SETTING_CURRENT_STATE: "SETTING_CURRENT_STATE",
  // #endregion

  // #region BI view types
  BI_VIEW_DATA_LOADING: "BI_VIEW_DATA_LOADING",
  BI_VIEW_DATA_FAILED: "BI_VIEW_DATA_FAILED",
  BI_VIEW_WEEKLY_COLUMNS: "BI_VIEW_WEEKLY_COLUMNS",
  LEGEND_UPDATE: "LEGEND_UPDATE",
  REPORT_BI_QUERY: "REPORT_BI_QUERY",
  SAVE_REPORT_DATA: "SAVE_REPORT_DATA",
  SET_TAB_COUNT: "SET_TAB_COUNT",
  // #endregion

  // #region advance view[scorecard + orders] view types
  SELECTED_ROWS_LIST: "SELECTED_ROWS_LIST",
  CHANGE_PAGE_SIZE: "CHANGE_PAGE_SIZE",
  CHANGE_STATUS: "CHANGE_STATUS",
  SC_NAME: "SC_NAME",
  SET_ORDER_TABLE_ORDERS: "SET_ORDER_TABLE_ORDERS",
  SET_ORDERS_COUNT: "SET_ORDERS_COUNT",
  SET_ORDER_ON_EXCLUDE: "SET_ORDER_ON_EXCLUDE",
  UPDATE_TAGS_ORDERS: "UPDATE_TAGS_ORDERS",
  RESET_ORDER_TABLE: "RESET_ORDER_TABLE",
  SHIPMENT_QUERY_DATE: "SHIPMENT_QUERY_DATE",
  FULFILMENT_CENTER_LIST: "FULFILMENT_CENTER_LIST",
  FULFILMENT_CENTER_ID: "FULFILMENT_CENTER_ID",
  SALES_CHANNEL_ID: "SALES_CHANNEL_ID",
  FULFILMENT_CENTER_SELECTION: "FULFILMENT_CENTER_SELECTION",
  SALES_CHANNEL_SELECTION: "SALES_CHANNEL_SELECTION",
  SET_ORDER_TABLE_PAGE: "SET_ORDER_TABLE_PAGE",
  SET_ORDERS_WITHOUT_CONCAT: "SET_ORDERS_WITHOUT_CONCAT",
  SET_CURRENT_CALL: "SET_CURRENT_CALL",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  CASE_ORDER_TABLE_LOADER: "CASE_ORDER_TABLE_LOADER",
  MARKED_SLA_COUNT: "MARKED_SLA_COUNT",
  MARKED_ROUTING_COUNT: "MARKED_ROUTING_COUNT",
  CLEAR_ADVANCE_FC_LIST: "CLEAR_ADVANCE_FC_LIST",
  SCORECARD_TAB: "SCORECARD_TAB",
  FETCHED_FC_ROUTES: "FETCHED_FC_ROUTES",
  // #endregion

  // #region onboarding view types
  CHANGE_STEP_COUNT: "CHANGE_STEP_COUNT",
  SET_SELECTED_FC: "SET_SELECTED_FC",
  SET_SELECTED_PLAN: "SET_SELECTED_PLAN",
  SET_PRICING_LIMIT: "SET_PRICING_LIMIT",
  SET_PLAN_STATE: "SET_PLAN_STATE",
  SET_SHOP_TYPE: "SET_SHOP_TYPE",
  SET_ROUTES: "SET_ROUTES",
  SET_FULFILMENT_CENTER_LIST: "SET_FULFILMENT_CENTER_LIST",
  SET_ONBOARD_SHOPNAME: "SET_ONBOARD_SHOPNAME",
  SET_ONBOAD_APIKEY: "SET_ONBOAD_APIKEY",
  SET_PROVIDER_ID: "SET_PROVIDER_ID",
  SET_ONBOARD_PASSWORD: "SET_ONBOARD_PASSWORD",
  SET_SHOPIFY_INTEGRATED: "SET_SHOPIFY_INTEGRATED",
  LOGOUT_ONBOARDING: "LOGOUT_ONBOARDING",
  SET_ONBOADING_USER: "SET_ONBOADING_USER",
  SET_CARD_ATTACHED: "SET_CARD_ATTACHED",
  ADD_ADMIN_USERS: "ADD_ADMIN_USERS",
  ADD_REGULAR_USERS: "ADD_REGULAR_USERS",
  SET_SCORECARD_SCHEDULE: "SET_SCORECARD_SCHEDULE",
  SET_SCORECARD_USERS: "SET_SCORECARD_USERS",
  SET_FC_HOLIDAYS: "SET_FC_HOLIDAYS",
  SET_SHOP_STATUS: "SET_SHOP_STATUS",
  RESET_ONBOARDING: "RESET_ONBOARDING",
  CLOSE_FREE_PLAN_POPUP: "CLOSE_FREE_PLAN_POPUP",
  ONBOARDING_LOADER: "ONBOARDING_LOADER",
  RESET_ONBOARD: "RESET_ONBOARD",
  SET_EXISTING_USER_DATA_FROM_LOCALSTORAGE: "SET_EXISTING_USER_DATA_FROM_LOCALSTORAGE",
  // #endregion

  // #region Skubana
  QUERY_PARAM_SKUBANA_CID: "QUERY_PARAM_SKUBANA_CID",
  QUERY_PARAM_SKUBANA_CODE: "QUERY_PARAM_SKUBANA_CODE",
  // #endregion

  // #onbaord
  CHANGE_FORM: "CHANGE_FORM",
  CHANGE_ONBOARD_STEP_COUNT: "CHANGE_ONBOARD_STEP_COUNT"
};
