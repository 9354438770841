import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import tabbar_logo from "../../../Resources/tabbarIcons/tabbar_logo1.png";
import { OnboardingInintState } from "../../../store/reducers/onboarding";
import { signOutUser } from "../../../utils/AuthWrapper";
import { USER_EMAIL } from "../../../utils/const";
import { useStyles } from "./styles";

export default function ButtonAppBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onboardingUser }: OnboardingInintState = useSelector((state: any) => state.onboarding);

  function handleLogout() {
    signOutUser().then(() => {
      const email = localStorage.getItem(USER_EMAIL);
      localStorage.removeItem("onboard_" + email);
      localStorage.removeItem("user_email");
      localStorage.removeItem("user_status");
      dispatch({ type: "RESET_ONBOARDING" });
      dispatch({ type: "RESET_ONBOARD" });
      dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
      dispatch({ type: "CLEAR_QUERY_PARAMS" });
      dispatch({ type: "LOGOUT_ONBOARDING" });
      dispatch({ type: "LOGOUT_ROOT" });
      window.location.href = "/";
    });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flex: 1 }}>
            <img src={tabbar_logo} alt="Capabl Logo" className={classes.logo} />
          </Box>
          {onboardingUser?.email && (
            <Button variant="text" onClick={handleLogout} sx={{ width: "100px", color: "#000" }}>
              Sign Out
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
