import { Grid, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonAppBar from "../../Common/Appbar/Index";
import { useHistory } from "react-router-dom";
import LeftSide from "../LeftSide/Index";
import RightSide from "../RightSide/Index";
import { useFormik } from "formik";
import { loginInitialValues, loginValidationSchema, onboardInitial } from "../data";
import { useSelector } from "react-redux";
import { OnboardInintState } from "../../../store/reducers/onboard";
import { useDispatch } from "react-redux";
import { SigninSteps } from "../data";
import { userSignIn } from "../../../utils/signInHelper";
import { RootInitStateInterface } from "../../../store/reducers/root";
import { OnboardingInintState } from "../../../store/reducers/onboarding";
import "../Index.css";
import { theme } from "../../../NewTheme";
import { Loader } from "../../../NewLoadingScreen";

function Index() {
  const state: OnboardInintState = useSelector((state: any) => state.onboard);
  const { stepCount, loader }: OnboardingInintState = useSelector((state: any) => state.onboarding);
  const { shopName, token }: RootInitStateInterface = useSelector((state: any) => state.root);
  const [step, setStep] = useState<number>(1);
  const [init, setInit] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const CurrentStep = SigninSteps[step];
  console.log(state, "======> state");
  const formik = useFormik({
    initialValues: loginInitialValues,
    validateOnChange: true,
    validationSchema: loginValidationSchema,
    onSubmit: async function () {
      console.log("on submit");
      // const isAuth =
      dispatch({ type: "ONBOARDING_LOADER", payload: true });
      await handleSignin(formik);
      dispatch({ type: "ONBOARDING_LOADER", payload: false });
      // if (isAuth) {
      //   formik.resetForm();
      //   history.push("/dashboard");
      // }
    },
  });

  useEffect(() => {
    if (stepCount > step) {
      setStep(stepCount);
    }
  }, [stepCount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { email, password } = state;
    formik.setValues({ email, password });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loginFormik = useFormik({
    initialValues: onboardInitial(state.provider, state.providerName, state.paymentPlan),
    validateOnChange: true,
    onSubmit: function (values) {},
  });

  useEffect(() => {
    const { email, password, provider, providerName, paymentPlan } = state;
    formik.setValues({ email, password });
    loginFormik.setValues({
      provider,
      providerName,
      paymentPlan,
    });
    setStep(stepCount);
    setTimeout(() => {
      setInit(false);
    }, 500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!init) {
      console.log("first");
      console.log({ ...loginFormik.values }, "loginformik start");
      dispatch({ type: "CHANGE_FORM", payload: { ...loginFormik.values } });
    }
  }, [loginFormik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const form = step === 0 ? formik : loginFormik;

  return (
    <ThemeProvider theme={theme}>
      {" "}
      <div className="bg-white" style={{ minHeight: "100vh", position: "relative" }}>
        {loader && <Loader content={loginLoaderContent[step]} />}
        <ButtonAppBar />
        <div
          style={{
            width: loader ? "100%" : "90%",
            margin: "auto",
            minHeight: "100vh",
          }}
        >
          <Grid container className="py-20" columnSpacing={3}>
            <Grid item xs={12} sm={5} md={6} lg={8}>
              <LeftSide step={step} />
            </Grid>
            <Grid item xs={12} sm={7} md={6} lg={4}>
              {step !== 1 && (
                <RightSide>
                  <CurrentStep step={step} formik={form} />
                </RightSide>
              )}
              {step === 1 && <CurrentStep step={step} formik={form} />}
            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );

  async function handleSignin(formik: any) {
    const { email, password } = formik.values;
    await userSignIn({
      emailAddress: email,
      password,
      setLoadings: () => {},
      token,
      shopName,
      history: { push: history.push, location: { search: history.location.search } },
      dispatch,
      setStep,
    });
    formik.resetForm();
  }
}

export default Index;

const loginLoaderContent = {
  0: "Just a moment please! We're logging in now",
  1: "Just a moment please! We're attaching your payment information",
  2: "Just a moment please! We're connecting to the shopify.",
};
