import { List, ListItem, Radio, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { StepsProps } from "../../styles";
import Stepper from "../Stepper/Index";
import Title from "../Title/Index";
import { PricingPlan } from "../../../../generated/graphql";

function Index(props: StepsProps) {
  const { formik, step, setStep } = props;
  const [selectedPlan, setSelectedPlan] = useState<string>("");

  useEffect(() => {
    if (selectedPlan) {
      formik.setFieldValue("paymentPlan", selectedPlan);
    }
  }, [selectedPlan]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formik.values.paymentPlan) {
      setSelectedPlan(formik.values.paymentPlan);
    }
  }, [formik.values.paymentPlan]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log(formik.values.paymentPlan, "paymentPlan");
  return (
    <Stepper setStep={setStep} formik={formik} step={step || 0}>
      <div className="">
        <Title style={{ marginBottom: 19 }}>
          <>Payment Plans</>
        </Title>
        <List className="p-7 overflow-auto">
          {plansList.map((plan) => (
            <ListItem
              key={plan.label}
              sx={listItemStyle}
              onClick={() => setSelectedPlan(plan.plan)}
            >
              <Radio checked={selectedPlan === plan.plan ? true : false} size="small" />
              <div className="flex flex-col">
                <Typography variant="h6">{plan.label}</Typography>
                <Typography variant="body2">{plan.subTitle}</Typography>
              </div>
              <div className="flex flex-row items-center">
                <Typography variant="h6">{plan.price}</Typography>
                <Typography variant="body2">/month</Typography>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </Stepper>
  );
}

export default Index;

const listItemStyle = {
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  backgroundColor: "#FAFAFA",
  border: "1px solid #E0E0E0",
  borderRadius: 2,
  marginBottom: "14px",
  padding: "12px 24px 12px 16px",
};

const plansList = [
  {
    label: "Free",
    price: "$0",
    plan: PricingPlan.Lite,
    subTitle:
      "Whether you have 2 or 10 locations, Capabl's pricing is simple, transparent and adapts to the need of your company.",
  },
  {
    label: "Basic",
    price: "$199",
    plan: PricingPlan.Starter,
    subTitle: "Support 1 fulfillment location and 1 integration with unlimited shipments.",
  },
  {
    label: "Plus",
    price: "$249",
    plan: PricingPlan.Plus,
    subTitle: "Support 2 fulfillment locations and up to 3 integrations with unlimited shipments.",
  },
  {
    label: "Premium",
    price: "$349",
    plan: PricingPlan.Ultra,
    subTitle:
      "Support unlimited fulfillment locations and up to 5 integrations with unlimited shipments.",
  },
];
